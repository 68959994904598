import Card from 'components/card';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Filter from 'components/filter';
import Pagination from 'components/pagination';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import { newsToCards } from 'sections/card-carousel';
import Hero from 'sections/hero';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import * as styles from './news-archive.module.scss';
import LangSlug from 'components/lang-slug';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type NewsArchivePageType = {
  pageContext: GatsbyTypes.SitePageContext;
  data: GatsbyTypes.NewsArchivePageQueryQuery;
};

const NewsArchive: React.FC<NewsArchivePageType> = ({ data, pageContext }) => {
  const {
    site,
    currentCategory,
    datoCmsNewsArchive,
    allDatoCmsCategory: { categories },
    allDatoCmsNews: { news },
  } = data;

  const { t } = useTranslation();

  const pathPrefix =
    site?.siteMetadata?.slugs?.find((s) => s?.model === 'news_archive')?.slug ?? '';

  const cardColumn: ColumnResponsiveSizes = {
    xl: 3,
    lg: 3,
    md: 4,
    sm: 6,
  };

  const seo = pageContext.categoryId
    ? currentCategory?.seoMetaTags
    : datoCmsNewsArchive?.seoMetaTags;

  const tags = [];

  if (pageContext.nextPagePath) {
    tags.push({
      tagName: 'link',
      attributes: {
        rel: 'next',
        href: `${pageContext.siteUrl}${pageContext.nextPagePath}`,
      },
    });
  }
  if (pageContext.previousPagePath) {
    tags.push({
      tagName: 'link',
      attributes: {
        rel: 'prev',
        href: `${pageContext.siteUrl}${pageContext.previousPagePath}`,
      },
    });
  }

  return (
    <>
      <HelmetDatoCms
        seo={{
          tags: [
            ...(seo?.tags ?? []).map((tag: { tagName: string; content?: string }) => {
              if (/title/i.test(tag.tagName) && (pageContext.pageNumber ?? 0) > 0) {
                const title = tag.content?.split(' - ');
                title?.splice(1, 0, `${t('Sida')} ${pageContext.humanPageNumber}`);

                return {
                  ...tag,
                  content: title?.join(' - '),
                };
              }

              return tag;
            }),
            ...tags,
          ],
        }}
      />
      <LangSlug
        langList={currentCategory?.langSlugs as GatsbyTypes.LangSlugFragment[]}
        model={pageContext.categoryId ? 'news_category' : 'news_archive'}
      />
      <Hero className={styles.hero}>
        <Column className={styles.heroContainer}>
          <Column className={styles.heroColumn} xl={6} lg={2} md={4} sm={6} xs={6}>
            <h1>{datoCmsNewsArchive?.title}</h1>
          </Column>
          <Column className={styles.heroColumn} xl={6} lg={10} md={8} sm={6} xs={6}>
            <Filter
              items={categories.map(({ slug, ...nc }) => ({
                id: nc.id as string,
                title: nc.pluralTitle as string,
                link: {
                  slug: slug as string,
                },
              }))}
              activeName={pageContext.categoryId ? currentCategory?.pluralTitle : undefined}
              pathPrefix={pathPrefix}
            />
          </Column>
        </Column>
      </Hero>
      <Section
        backgroundColor={'light'}
        topBackgroundColor={'blue'}
        topBackgroundOffset={'120px'}
        className={styles.newsSection}
      >
        {newsToCards(news as GatsbyTypes.DatoCmsNews[]).map((card) => (
          <Column key={card.id} className={styles.newsItem} {...cardColumn}>
            <Card {...card} />
          </Column>
        ))}
      </Section>
      <Pagination
        {...pageContext}
        pathPrefix={`${pathPrefix}${pageContext.categoryId ? '/' + currentCategory?.slug : ''}`}
      />
    </>
  );
};

export default NewsArchive;

export const query = graphql`
  query NewsArchivePageQuery(
    $skip: Int!
    $limit: Int!
    $categoryId: String
    $categoryIds: [String]
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        slugs {
          model
          slug
        }
      }
    }
    datoCmsNewsArchive {
      title
      seoMetaTags {
        tags
      }
    }
    currentCategory: datoCmsCategory(id: { eq: $categoryId }) {
      slug
      pluralTitle
      langSlugs {
        ...LangSlug
      }
      seoMetaTags {
        tags
      }
    }
    allDatoCmsNews(
      skip: $skip
      limit: $limit
      filter: { category: { id: { eq: $categoryId } } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      news: nodes {
        id
        slug
        title
        model {
          apiKey
        }
        meta {
          publishedAt: firstPublishedAt(formatString: "YYYY-MM-DD")
        }
        category {
          id
          title
          slug
        }
        featuredImage {
          ...Image
        }
      }
    }
    allDatoCmsCategory(filter: { id: { in: $categoryIds } }, sort: { fields: title, order: ASC }) {
      categories: nodes {
        slug
        title
        pluralTitle
        id
      }
    }
  }
`;
